import { axios } from '@/utils/request'
import { baseIp } from '@/config/appSetting'


/**
 * 新闻
 */
export function getNews (parameter) {
    return axios({
        url: baseIp+'News/NewsList',
        method: 'get',
        params: parameter
    })
}
export function getNewDetails (parameter,id) {
    return axios({
        url: baseIp+'News/'+id,
        method: 'get',
        params: parameter
    })
}
/**
 * 公共底部
 */
export function getBasicConfig (parameter) {
    return axios({
        url: baseIp+'BasicConfig/BasicConfigs',
        method: 'get',
        params: parameter
    })
}
/**
 * 产品案例
 */
export function getProductCase (parameter) {
    return axios({
        url: baseIp+'ProductCase/ProductCases',
        method: 'get',
        params: parameter
    })
}
export function getProductType (parameter) {
  return axios({
      url: baseIp+'Module/Modules',
      method: 'get',
      params: parameter
  })
}
export function getProductCasePage (parameter,id) {
    return axios({
        url: baseIp+'ProductCase/'+id,
        method: 'get',
        params: parameter
    })
}
/**
 * 首页
 */
export function getIndex (parameter) {
    return axios({
        url: baseIp+'Universal/Screen',
        method: 'get',
        params: parameter
    })
}
export function addMessage (parameter) {
    return axios({
        url: baseIp+'Message',
        method: 'post',
        data: parameter
    })
}
/*加入我们 */
export function getJob (parameter) {
  return axios({
      url: baseIp+'JobOffers/Jobs',
      method: 'get',
      params: parameter
  })
}
export function getJobDetails (parameter,id) {
  return axios({
      url: baseIp+'JobOffers/'+id,
      method: 'get',
      params: parameter
  })
}
/*合作说明*/
export function getCoo (parameter) {
  return axios({
      url: baseIp+'Cooperation/Cooperations',
      method: 'get',
      params: parameter
  })
}