<template>
<div style="overflow:hidden">
  <div class="m-head-box">
    <div class="logo-box" @click="jumpIndex()">
        <img class="img-logo"  alt="郑州郑大智能科技股份有限公司" src="@/assets/img/mlogo-color.png">
    </div>
    <div class="nav-box" :class="{'open':isShow}">
      <div class="burger" @click="navShow()">
          <div class="x line rotate45"></div>
          <div class="y line"></div>
          <div class="z line rotate135"></div>
      </div>
       <div class="menu-bg" :class="{'animate':isShow}" @touchmove.prevent >
        <a-menu   v-model="current" class="nav-ul">
            <a-menu-item :class="{'selected':selectVal==(item.children?item.children[0].meta.title:item.meta.title)}" v-for="item in mMenu"  v-show="item.path!=='/MExperience'" :key="item.children?item.children[0].meta.title:item.meta.title"  @click="toJump(item)"><span>{{item.children?item.children[0].meta.title:item.meta.title}}</span></a-menu-item>
        </a-menu>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import {mMenu} from'@/config/router.config'
export default {
  name: 'mGlobalHeader',
  data() {
    return {
      mMenu,
      current:[],
      isShow:false,
      selectVal:''
    }
  },
  mounted() {
     this.current=[this.$route.meta.title]
     this.selectVal=this.$route.meta.title
     window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    preD(e){
      console.log(e)
      e.preventDefault()
    },
    navShow(){
      this.isShow=!this.isShow
      if(!this.isShow){
        document.body.style.overflow = '' // 出现滚动条
        document.removeEventListener('touchmove', this.preD, {passive: false})
      }else{
        document.body.style.overflow = 'hidden'
        document.addEventListener('touchmove', this.preD, {passive: false}) // 禁止页面滑动
      }
    },
    toJump(item){
       this.navShow()
       if(item.children){
          this.selectVal=item.children?item.children[0].meta.title:item.meta.title
          this.$router.push({path:item.path})
       }else{
          window.open(item.path, '_blank')
       }
    },
    jumpIndex(){
     this.$router.push({path:'/MIndex'})
    }
  },
  watch: {
      $route(newVal,oldVal){
        this.current=[newVal.meta.title]
        this.nowPage=newVal.meta.title
      }
  },
}
</script>
<style lang="less" scoped>
.m-head-box{
  position: fixed;
  width: 100%;
  height: 1.333333rem /* 100/75 */;
  padding: 0;
  line-height: 1.333333rem /* 100/75 */;
  background: #ffffff;
  z-index: 1111;
  box-shadow: 0px 0px 10px 0px rgba(158, 158, 158, 0.51);
  .logo-box{
    float: left;
    padding-left: .586667rem /* 44/75 */;
    position: relative;
    z-index: 2;
    .img-logo{
      width: 2.933333rem /* 220/75 */;
    }
  }
  .nav-box{
    height: 100%;
    text-align: right;
    position: relative;
    div.burger {
      height:.666667rem /* 50/75 */;
      width:.666667rem /* 50/75 */;
      position:absolute;
      top:50%;
      margin-top: -.333333rem /* 25/75 */;
      right:.586667rem /* 44/75 */;
      cursor:pointer;
      z-index: 10000;
        .line{
            position:absolute;
            margin:auto;
            top:0;
            bottom:.266667rem /* 20/75 */;
            right: 0;
            height: 3px;
            width: 90%;
            background:#274680;
            border-radius:2px;
            -webkit-transition:all 200ms ease-out;
            -moz-transition:all 200ms ease-out;
            -ms-transition:all 200ms ease-out;
            -o-transition:all 200ms ease-out;
            transition:all 200ms ease-out
         }
         .x{
           top:0;
         }
         .y{
           top:.346667rem /* 26/75 */;
         }
         .z{
           top: .693333rem /* 52/75 */;
         }
    }
  }
   
}
.open .rotate45 {
    top:.693333rem /* 52/75 */!important;
    background:#fff !important;
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
}
.open .rotate135 {
    background:#fff !important;
    -ms-transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transition: all 100ms ease-out;
    -moz-transition: all 100ms ease-out;
    -ms-transition: all 100ms ease-out;
    -o-transition: all 100ms ease-out;
    transition: all 100ms ease-out;
}
.open .y{
  display: none !important;
}
div.menu-bg.animate {
    display: inline-block;
    opacity: .96;
    -webkit-transition: all 400ms cubic-bezier(0,.995,.99,1);
    -moz-transition: all 400ms cubic-bezier(0,.995,.99,1);
    -ms-transition: all 400ms cubic-bezier(0,.995,.99,1);
    -o-transition: all 400ms cubic-bezier(0,.995,.99,1);
    transition: all 400ms cubic-bezier(0,.995,.99,1);
}
div.menu-bg {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left:0;
  z-index: 9999;
  background: #40435b;
  display: none;
  opacity: 0;
  -webkit-transition: all 300ms cubic-bezier(0,.995,.99,1);
  -moz-transition: all 300ms cubic-bezier(0,.995,.99,1);
  -ms-transition: all 300ms cubic-bezier(0,.995,.99,1);
  -o-transition: all 300ms cubic-bezier(0,.995,.99,1);
  transition: all 300ms cubic-bezier(0,.995,.99,1);
  padding-top:20vh;
  text-align: center;
  ul{
    background: transparent;
    border-right: 0;
    width: 4.2rem /* 240/75 */;
    margin:0 auto;
    li{
      color:#fff;
      font-size: .426667rem /* 32/75 */;
      height:1.333333rem /* 100/75 */;
      span{
        display: inline-block;
      }
    }
  }
}
 .ant-menu-item-selected{
   background: transparent !important
 }
 .ant-menu-item-selected span{
   border-bottom: 2px solid 40435b;
 }
.selected span{
   border-bottom: 2px solid #fff;
 }
 html,body{
   width: 100%;
 }
</style>
<style scoped>

</style>

