<template>
<div class="box nav-scroll">
    <div class="nav-box">
      <div class="logo-box" @click="jumpIndex()">
          <img alt="郑州郑大智能科技股份有限公司" src="@/assets/indexImg/logo.png">
      </div>
      <a-menu  mode="horizontal" v-model="current" class="nav-ul">
        <a-menu-item :class="{'selected':selectVal==(item.children?item.children[0].meta.title:item.meta.title)}" v-for="item in routerList" :key="item.children?item.children[0].meta.title:item.meta.title"  @click="toJump(item)">{{item.children?item.children[0].meta.title:item.meta.title}}</a-menu-item>
      </a-menu>
    </div>
</div>
</template>
<script>
import {pcMenu} from'@/config/router.config'
export default {
  name: 'GlobalHeader',
  data() {
      return {
        routerList:pcMenu,
        current:[],
        isScroll:false,
        nowPage:'首页',
        selectVal:''
      }
  },
  mounted() {
     this.current=[this.$route.meta.title]
     this.selectVal=this.$route.meta.title
  },
  methods: {
      toJump(item){
          if(item.children){
            this.selectVal=item.children?item.children[0].meta.title:item.meta.title
            this.$router.push({path:item.path})
          }else{
            window.open(item.path, '_blank')
          }
      },
       jumpIndex(){
      this.$router.push({path:'/Index'})
      }
  },
  beforeDestroy() {
  },
  watch: {
      $route(newVal,oldVal){
        this.current=[newVal.meta.title]
        this.nowPage=newVal.meta.title
      }
  },
}
</script>
<style lang="less" scoped>
.box{
  position: relative;
  z-index: 100000;
  width: 100%;
  padding: 20px 0 0;
  overflow: hidden;
  .nav-box{
      width: 1400px;
      height: 100%;
      margin: 0 auto;
      .logo-box{
          float: left;
          cursor: pointer;
      }
      .nav-ul{
          float: right;
          li{
              padding: 0;
              margin-left: 10px;
              width: 120px;
              height: 35px;
              line-height: 35px;
              border-radius: 4px;
              border-bottom: none;
          }
      }
  }
  
}
.nav-box{
    .ant-menu-horizontal{
        background: transparent;
        border-color: transparent;
        font-size: 16px;
        letter-spacing: 0px;
    }
}
.nav-scroll{
   .nav-box {
      .ant-menu-horizontal{
          color:#fff;
      }
   }
}
.ant-menu-horizontal > .ant-menu-item-selected,.ant-menu-horizontal > .ant-menu-item:hover{
    color: #fff;
    background-color: #00d4b9;
   
}
.ant-menu-horizontal > .ant-menu-item-selected{
    background: transparent;
}
.selected {
    background-color: #00d4b9 !important;
}
</style>
<style scoped>
@media only screen  and (max-width: 1600px) {
    .nav-box{
        width: 1200px !important;
        
    }
    .nav-box .nav-ul  li{
        margin-left: 20px !important;
    }
}
@media only screen  and (max-width: 1250px) {
    .nav-box{
        width:1000px !important;
        
    }
    .nav-box .nav-ul  li{
        margin-left: 0px !important;
    }
}
</style>


