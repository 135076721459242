<template>
<div class="box nav-scroll">
    <div class="nav-box">
      <div class="logo-box" @click="jumpIndex()">
          <img alt="郑州郑大智能科技股份有限公司" src="@/assets/img/logo-color.png">
      </div>
      <a-menu  mode="horizontal" v-model="current" class="nav-ul">
        <a-menu-item  :class="{'selected':selectVal==(item.children?item.children[0].meta.title:item.meta.title)}" v-for="item in routerList" :key="item.children?item.children[0].meta.title:item.meta.title" @click="toJump(item)">{{item.children?item.children[0].meta.title:item.meta.title}}</a-menu-item>
      </a-menu>
    </div>
</div>
</template>
<script>
import {pcMenu} from'@/config/router.config'
export default {
  name: 'GlobalHeader',
  data() {
      return {
        routerList:pcMenu,
        current:[],
        isScroll:false,
        nowPage:'首页',
        selectVal:''
      }
  },
  mounted() {
    this.current=[this.$route.meta.title]
    this.selectVal=this.$route.meta.title
  },
  methods: {
      toJump(item){
         if(item.children){
            this.selectVal=item.children?item.children[0].meta.title:item.meta.title
            this.$router.push({path:item.path})
          }else{
            window.open(item.path, '_blank')
          }
      },
       jumpIndex(){
      this.$router.push({path:'/Index'})
      }
  },
  beforeDestroy() {
  },
  watch: {
      $route(newVal,oldVal){
        this.current=[newVal.meta.title]
        this.nowPage=newVal.meta.title
      }
  },
}
</script>
<style lang="less" scoped>
.box{
  position: fixed;
  left: 0;
  top:0;
  z-index: 100000;
  width: 100%;
  height: 60px;
  line-height:60px;
  padding: 0;
  background:rgba(0, 0, 0, .6);
  overflow: hidden;
  .nav-box{
      width: 1400px;
      height: 100%;
      margin: 0 auto;
      .logo-box{
          float: left;
          cursor: pointer;
      }
      .nav-ul{
          float: right;
          li{
              padding: 0;
              margin-left: 90px;
          }
      }
  }
  
}
.nav-box{
    .ant-menu-horizontal{
        line-height: 58px;
        background: transparent;
        color:#fff;
        border-color: transparent;
        font-size: 15px;
        letter-spacing: 0px;
    }
}
.nav-scroll{
   background:#fff !important;
   box-shadow:0 2px 8px 0 rgba(0,0,0,.16);;
   .nav-box {
     .ant-menu-horizontal{
        color:#333;
    }
   }
}
.ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected {
    color: #333;
    border-bottom: 2px solid #fff;
}
.selected {
    color: #1890ff !important;
    border-bottom: 2px solid #1890ff !important;
}
</style>
<style scoped>
@media only screen  and (max-width: 1600px) {
    .nav-box{
        width: 1200px !important;
        
    }
    .nav-box .nav-ul  li{
        margin-left: 70px !important;
    }
}
@media only screen  and (max-width: 1250px) {
    .nav-box{
        width: 1000px !important;
        
    }
    .nav-box .nav-ul  li{
        margin-left: 60px !important;
    }
}

</style>


