<template>
  <div class="m-layout-box">
    <a-layout>
      <a-layout-header class="layout-header" >
        <globalHeader/>
      </a-layout-header> 
      <a-layout-content class="m-content">
        <router-view/>
      </a-layout-content>
      <a-layout-footer class="layout-foot" :style="{'background':'#fff',padding:0}" >
        <globalFooter/>
      </a-layout-footer> 
  </a-layout>
  </div>
</template>
<script>
import globalHeader from'@/components/GlobalHeader/mGlobalHeader'
import globalFooter from'@/components/GlobalFooter/mGlobalFooter'
export default {
  name: 'BasicLayout',
  components:{
    globalHeader,
    globalFooter
  }
}
</script>

<style lang="less" scoped>
.m-layout-box{
  .layout-header{
    background: #ffffff;
    padding: 0;
    overflow: hidden;
    height: 1.333333rem /* 100/75 */;
    width: 100%;
  }
  .m-content{
    min-height:50vh;
    background-color: #f0f6fe;
    width: 100%;
    overflow: hidden;
  }
}
</style>
<style lang="less">
 
</style>
