<template>
  <div class="layout-box">
    <a-layout>
      <a-layout-header class="layout-header" :style="{'background-color':'transparent','position':'absolute','width':'100%'}">
        <globalHeader/>
      </a-layout-header> 
      <a-layout-content :style="{overflow:'hidden' }">
        <router-view/>
      </a-layout-content>
      <a-layout-footer class="layout-foot" :style="{ textAlign: 'center' }">
        <globalFooter/>
      </a-layout-footer> 
  </a-layout>
  </div>
</template>
<script>
import globalHeader from'@/components/GlobalHeader/GlobalHeader'
import globalFooter from'@/components/GlobalFooter/GlobalFooter'
export default {
  name: 'BasicLayout',
  components:{
    globalHeader,
    globalFooter
  }
}
</script>

<style lang="less" scoped>
@import '../assets/css/common.css';
.layout-box{
  overflow: hidden;
  min-width: 1200px;
  font-family: 'Microsoft YaHei','Avenir', Helvetica, Arial, sans-serif;

  .layout-foot{
    padding: 0;
    margin: 0;
  }
}
</style>
<style lang="less">
  .ant-layout{
    background: #fff;
  }

</style>
