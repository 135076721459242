// import '@babel/polyfill'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'
import {Layout,Row,Col,Menu,Tabs,List,Icon,Modal,Form,Input,BackTop,message,Spin,Timeline,Anchor,Collapse,Breadcrumb,tooltip} from 'ant-design-vue'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import store from './store'
import swiperAni from './assets/js/swiper.animate1.0.3.min'
import 'swiper/dist/css/swiper.css'
import 'animate.css'
import App from './App.vue'
import router from './router'
import 'babel-polyfill'


Vue.config.productionTip = false
Vue.use(Layout)
Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(Tabs)
Vue.use(List)
Vue.use(Icon)
Vue.use(Modal)
Vue.use(Form)
Vue.use(Input)
Vue.use(BackTop)
Vue.use(VueAwesomeSwiper)
Vue.use(Spin)
Vue.use(Timeline)
Vue.use(Anchor)
Vue.use(Collapse)
Vue.use(Breadcrumb)
Vue.use(tooltip)
Vue.use(Viewer,{
  defaultOptions: {
    zIndex: 999999999999999,
    title:0,
    toolbar: {
      zoomIn: 4,
      zoomOut: 4,
      oneToOne: 4,
      reset: 4,
      prev: 4,
      play: 0,
      next: 4,
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0,
    },
  }
})
Vue.prototype.$message = message
message.config({
  top: `200px`,
});
Vue.prototype.$swiperAnimateCache=swiperAni.swiperAnimateCache
Vue.prototype.$swiperAnimate=swiperAni.swiperAnimate
Vue.prototype.$clearSwiperAnimateCache=swiperAni.clearSwiperAnimateCache
//监听sessionStorage
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'bannerList') {
      var newStorageEvent = document.createEvent('StorageEvent');
      const storage = {
          setItem: function (k, val) {
              sessionStorage.setItem(k, val);
              newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
              window.dispatchEvent(newStorageEvent)
          }
      }
      return storage.setItem(key, newVal);
  }
}
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    if(to.meta.title=='首页'){
      document.title = '郑州郑大智能科技股份有限公司'
    }else{
      document.title = to.meta.title+'-郑州郑大智能科技股份有限公司'
    }
  }
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
