<template>
  <div class="m-footer-box">
     <a-row  class="top-box">
        <a-col class="gutter-row" :span="24">
          <div class="gutter-box">
             <div class="right-box">
                <img class="code-img" :src="imgUrl" alt="">
                <span class="img-txt fontColor6" >长按保存图片，在微信中扫描该二维码</span>
            </div>
          </div>
      </a-col>
     </a-row>
     <div class="bottom-box">
        <p class="fontColor"><a href="https://beian.miit.gov.cn" target="_blank" style="text-decoration:none;color: #000;font-size:0.32rem /* 24/75 */" > {{config.recordNumber}}</a>
        <a href="/certificates.html" target="_blank" style="text-decoration:none;color: #000;font-size:0.32rem /* 24/75 */; margin-left: 15px;" >证照中心</a></p>
        <p class="fontColor">Copyright &copy; 2021 郑州郑大智能科技股份有限公司</p>
        <p class="fontColor">{{config.address}}</p>
        <p class="fontColor">{{config.email}}</p>
     </div>
     <div class="contact-box" :class="{'show':show}">
        <ul>
          <li>  <a target="_blank" :href="`https://wpa.qq.com/msgrd?v=3&uin=${config.qq}&site=qq&menu=yes`">在线咨询</a></li>
          <li>电话咨询：<span v-for="(item,index) in config.phoneNumber" :key="index" v-show="index<1" style=""><a :href="`tel:${item}`">{{item}}</a></span></li>
        </ul>
     </div>
  </div>
</template>
<script>
import { getBasicConfig } from '@/api/data'
import  { newsImg } from '@/config/appSetting'
export default {
  name: 'mGlobalFooter',
  data() {
      return {
        newsImg,
        config:{},
        imgUrl:'',
        show:true
      }
  },
  mounted() {
    getBasicConfig().then(res => {
            if (res.state) {
              if(res.data){
                this.config=res.data?res.data:{}
                this.imgUrl=this.newsImg+this.config.qrUrl
                this.config.qq=this.config.qq||'1234566'
                this.bannerList=this.config.bannerItems&&this.config.bannerItems.length>0?this.config.bannerItems:[]
                sessionStorage.setItem("email", res.data.email);
                sessionStorage.setItem("address", res.data.address);
              }
            }
    }).then(()=>{
      this.resetSetItem('bannerList',JSON.stringify(this.bannerList));
    })
  },
  methods: {
   
  },
  watch: {
    
  },
}
</script>
<style lang="less" scoped>
.m-footer-box{
  padding-bottom: 1.6rem /* 120/75 */;
  .top-box{
    padding: 0 .586667rem /* 44/75 */;
    .gutter-box{
      padding: 0;
    }
    .left-box{
      .tel{
        width:60%;
        margin:.133333rem /* 10/75 */ auto;
        .left-img{
          float: left;
          width: 1.2rem /* 90/75 */;
          height: 1.2rem /* 90/75 */;
        }
        h3{
          font-size: .373333rem /* 28/75 */;
          font-weight: normal;
        }
      }
    }
    .info-box{
      font-size:.32rem /* 24/75 */;
      p{
        margin-bottom: .133333rem /* 10/75 */;
      }
    }
    .right-box{
      text-align: center;
      margin-bottom:.4rem /* 30/75 */;
      .code-img{
        display: block;
        width: 2.76rem /* 207/75 */;
        height: 2.76rem /* 207/75 */;
        margin:.8rem /* 60/75 */ auto 0;
      }
      .img-txt{
        font-size:.333333rem /* 25/75 */;
        line-height: .666667rem /* 50/75 */;
      }
    }
  }
  .bottom-box{
    position: relative;
    p{
      font-size: .32rem /* 24/75 */;
      margin-bottom: .066667rem /* 5/75 */;
    }
    img{
      position: absolute;
      top:-.266667rem /* 20/75 */;
      left: 0;
      right: 0;
      margin:0 auto;
      width: 2.666667rem /* 200/75 */;
    }
  }
  .contact-box{
    position: fixed;
    width: 100%;
    bottom: -1.333333rem /* 100/75 */;
    z-index: 999;
  }
  .contact-box li {
    float: left;
    height:1.333333rem /* 100/75 */;
    background: #fff;
    line-height:1.333333rem /* 100/75 */;
    color: #fff;
    font-size: .373333rem /* 28/75 */;
  }
  .contact-box li:nth-child(1) {
    width: 30%;
    border-top: 1px solid #d7d7d7;
  }
  .contact-box li:nth-child(2) {
    width: 70%;
    background: #4285f4;
    a{
      color:#fff;
    }
  }
}
.fontColor{
   color: #000;
  //  font-stretch: normal;
}
a:visited {
    text-decoration: none;
}
.show{
  bottom:0 !important;
}
.fontColor6{
  color:#666;
}
</style>
<style lang="less">

</style>

