<template>
  <div id="app" :class="{'hidden':show,'heightauto':!show}">
     <a-spin v-show="show" />
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isPC:true,
      show:true
    }
  },
  mounted() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      this.isPC=false
    }else{
      this.isPC=true
    }
    setTimeout(()=>{this.show=false}, 3000);

  },
  methods: {
    jump(name, query) {
      this.$router.push({ name: name, query });
    }
  },
  watch: {
    $route: {
      handler: function(val, oldVal) {
        let _this = this;
        let query = val.query;
        if (!this.isPC && val.name.indexOf("M") == -1) {
            this.show=true
            this.jump("M" + val.name, query);
            setTimeout(()=>{this.show=false}, 3000);
        }
        if (this.isPC && val.name.indexOf("M") > -1) {
           this.show=true
           this.jump(val.name.substring(1, val.name.length), query);
           setTimeout(()=>{this.show=false}, 3000);
        }
      },
      deep: true
    }
  }
}
</script>
<style lang="less">
#app {
  //  font-family: 'Microsoft YaHei','Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.ant-spin.ant-spin-spinning {
    position: absolute;
    top: 0;
    background: #fff;
    z-index: 6666666;
    width: 100%;
    height: 100%;
    left: 0;
    box-sizing: border-box;
    .ant-spin-dot-spin{
      position: absolute;
      top:45%;
      right:0;
      left: 0;
      margin:0 auto;
    }
}
.hidden{
  overflow: hidden;
}
.heightauto{
  height: auto !important;
}
</style>
