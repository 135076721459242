// eslint-disable-next-line
import {BasicLayout,mBasicLayout,indexLayout,mindexLayout } from '@/layouts'
import defaultSettings from 'defaultconfig'
/**
 * 路由
 * @type { *[] }
 */
export const pcMenu=[
  {
    path: '/',
    component: indexLayout,
    redirect: '/Index',
    children: [
      {
        path: '/Index',
        name: 'Index',
        meta: { title: '首页' },
        component: () => import('@/views/Index/index2')
      }
    ]
  },
  // {
  //   path: '/',
  //   component: BasicLayout,
  //   redirect: '/Index',
  //   children: [
  //     {
  //       path: '/Index',
  //       name: 'Index',
  //       meta: { title: '首页' },
  //       component: () => import('@/views/Index/index')
  //     }
  //   ]
  // },
  {
    path: '/About',
    component: BasicLayout,
    redirect: '/About',
    children: [
      {
        path: '/About',
        name: 'About',
        meta: { title: '关于我们' },
        component: () => import('@/views/About/about')
      }
    ]
  },
  {
    path: '/Business',
    component: BasicLayout,
    redirect: '/Business',
    children: [
      {
        path: '/Business',
        name: 'Business',
        meta: { title: '业务介绍' },
        component: () => import('@/views/Business/business')
      },
      {
        path: '/BusinessPage/:id',
        name: 'BusinessPage/:id',
        meta: { title: '产品中心' },
        component: () => import('@/views/Business/businessPage')
     }
    ]
  },
  {
    path: defaultSettings.semiconductor,
    meta: { title: '半导体事业部',  target: '_blank' },
  },
  {
    path: '/Cooperation',
    component: BasicLayout,
    redirect: '/Cooperation',
    children: [
      {
        path: '/Cooperation',
        name: 'Cooperation',
        meta: { title: '合作说明' },
        component: () => import('@/views/Cooperation/cooperation')
      }
    ]
  },
  {
    path: '/News',
    component: BasicLayout,
    redirect: '/News',
    children: [
      {
        path: '/News',
        name: 'News',
        meta: { title: '新闻动态' },
        component: () => import('@/views/News/news')
      },
      {
        path: '/NewDetails/:id',
        name: 'NewDetails/:id',
        meta: { title: '新闻动态' },
        component: () => import('@/views/News/newDetails')
      }
    ]
  },
  {
    path: '/Work',
    component: BasicLayout,
    redirect: '/Work',
    children: [
      {
        path: '/Work',
        name: 'Work',
        meta: { title: '工作机会' },
        component: () => import('@/views/Work/work')
      },
      {
        path: '/WorkDetails/:id',
        name: 'WorkDetails/:id',
        meta: { title: '招聘详情' },
        component: () => import('@/views/Work/workDetails')
      }
    ]
  },
  
]
 export const mMenu=[
  {
    path: '/MIndex',
    component: mindexLayout,
    redirect: '/MIndex',
    children: [
      {
        path: '/MIndex',
        name: 'MIndex',
        meta: { title: '首页' },
        component: () => import('@/views/M/Index/index2')
      },
     
    ]
  },
  {
    path: '/MExperience',
    component: mBasicLayout,
    redirect: '/MExperience',
    children: [
      {
        path: '/MExperience',
        name: 'MExperience',
        meta: { title: '立即体验' },
        component: () => import('@/views/M/Index/experience')
      }
    ]
  },
  {
    path: '/MAbout',
    component: mBasicLayout,
    redirect: '/MAbout',
    children: [
      {
        path: '/MAbout',
        name: 'MAbout',
        meta: { title: '关于我们' },
        component: () => import('@/views/M/About/about')
      }
    ]
  },
  {
    path: '/MBusiness',
    component: mBasicLayout,
    redirect: '/MBusiness',
    children: [
      {
        path: '/MBusiness',
        name: 'MBusiness',
        meta: { title: '业务介绍' },
        component: () => import('@/views/M/Business/business')
      },
      {
        path: '/MBusinessPage/:id',
        name: 'MBusinessPage/:id',
        meta: { title: '产品中心' },
        component: () => import('@/views/M/Business/businessPage')
     }
    ]
  },
  {
    path: defaultSettings.semiconductor,
    meta: { title: '半导体事业部',  target: '_blank' },
  },
  {
    path: '/MCooperation',
    component: mBasicLayout,
    redirect: '/MCooperation',
    children: [
      {
        path: '/MCooperation',
        name: 'MCooperation',
        meta: { title: '合作说明' },
        component: () => import('@/views/M/Cooperation/cooperation')
      }
    ]
  },
  {
    path: '/MNews',
    component: mBasicLayout,
    redirect: '/MNews',
    children: [
      {
        path: '/MNews',
        name: 'MNews',
        meta: { title: '新闻动态' },
        component: () => import('@/views/M/News/news')
      },
      {
        path: '/MNewDetails/:id',
        name: 'MNewDetails/:id',
        meta: { title: '新闻动态' },
        component: () => import('@/views/M/News/newDetails')
      }
    ]
  },
  {
    path: '/MWork',
    component: mBasicLayout,
    redirect: '/MWork',
    children: [
      {
        path: '/MWork',
        name: 'MWork',
        meta: { title: '工作机会' },
        component: () => import('@/views/M/Work/work')
      },
      {
        path: '/MWorkDetails/:id',
        name: 'MWorkDetails/:id',
        meta: { title: '招聘详情' },
        component: () => import('@/views/M/Work/workDetails')
      }
    ]
  }
]
export const constantRouterMap =pcMenu.concat(mMenu)
