<template>
  <div class="footer-box">
    <div class="top-box">
        <div class="left-box">
             <div class="tel-box"> 
                 <h4 class="fontColor">咨询热线</h4>
                <h3  class="fontColor" :class="{'one':config.phoneNumber.length==1}" v-for="(item,index) in config.phoneNumber" :key="index">{{item}}</h3> 
             </div>
        </div>
        <div class="center-box">
          <p class="copyright ">
            <a href="https://beian.miit.gov.cn" target="_blank" style="text-decoration:none; color: #94bdeb;" ><span>{{config.recordNumber}} </span>
            </a> 
            <a href="/certificates.html" target="_blank" style="text-decoration:none; color: #94bdeb; margin-left: 15px;" > <span>证照中心</span></a> 
           </p>
           <p>
             Copyright &copy; 2021 郑州郑大智能科技股份有限公司
           </p>
            <p>地址：{{config.address}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;邮箱：{{config.email}}</p>
        </div>
        <div class="right-box">
            <img class="code-img" :src="imgUrl" alt="">
             <span class="img-txt">官方微信公众号</span>
        </div>
    </div>
    <div class="right-fix-box" ref="fixBox">
        <ul class="fixed">         
            <li class="fixed-box">
                <span class="words">
                    <a-icon type="qq" class="next-icon" style="font-size:26px" />
                </span>
                 <div class="fixedDisplay" style="line-height:40px">
                    <div class="box">
                          <a target="_blank" :href="`https://wpa.qq.com/msgrd?v=3&uin=${config.qq}&site=qq&menu=yes`"><img border="0" src="@/assets/img/qq.jpg" alt="点击这里给我发消息" title="点击这里给我发消息"/></a>
                     </div>
                </div>
            </li>
             <li class="fixed-box">
                <span class="words">
                    <a-icon type="wechat" class="next-icon" style="font-size:26px"  />
                </span>
                 <div class="fixedDisplay" style="">
                     <div class="box">
                         <img class="code-img" :src="imgUrl" alt="" style="width:134px;height:134px">
                     </div>
                </div>
            </li>
             <li class="fixed-box">
                <span class="words">
                    <a-icon type="phone"  class="next-icon" style="font-size:24px;"  />
                </span>
                <div class="fixedDisplay" style="">
                    <div class="box" v-if="config.phoneNumber" :class="{'linehight':config.phoneNumber.length<2}">
                         <div class="" v-for="(item,index) in config.phoneNumber" :key="index" v-show="index<2" style="">{{item}}</div>
                    </div>
                </div>
            </li>
             <li class="fixed-box">
                <a-back-top :visibilityHeight="100">
                  <a-icon type="up" style="font-size:30px;line-height:70px" />
                </a-back-top>
            </li>
        </ul>
    </div>
  </div>
</template>
<script>
import { getBasicConfig } from '@/api/data'
import  { newsImg } from '@/config/appSetting'
export default {
  name: 'GlobalFooter',
  data() {
      return {
        newsImg,
        config:{},
        imgUrl:'',
        scrollTop:0
      }
  },
  mounted() {
       window.addEventListener("scroll", this.handleScroll);
       getBasicConfig().then(res => {
            if (res.state) {
              if(res.data){
                this.config=res.data?res.data:{}
                this.imgUrl=this.newsImg+this.config.qrUrl
                this.config.qq=this.config.qq||'1234566'
                this.bannerList=this.config.bannerItems&&this.config.bannerItems.length>0?this.config.bannerItems:[]
                sessionStorage.setItem("email", res.data.email);
                sessionStorage.setItem("address", res.data.address);
              }
            }
         }).then(()=>{
        this.resetSetItem('bannerList',JSON.stringify(this.bannerList));
      })
  },
  methods: {
    jump (item) {
      if(item.type==1)  this.$router.push({path:'/ProductPage/:'+item.id})
      if(item.type==2)  this.$router.push({path:'/Solution/'+item.id})
    },
    handleScroll() {
        let scrollTop =window.pageYOffset ||document.documentElement.scrollTop ||document.body.scrollTop;
        this.scrollTop=scrollTop
        if(scrollTop>700){
            this.$refs.fixBox.classList.add("show")
        }else{
           this.$refs.fixBox.classList.remove("show")
        }
      }
  },
  beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    
  },
}
</script>
<style lang="less" scoped>
.footer-box{
    position: relative;
    z-index: 66;
    margin-top: -245px;
    height: 245px;
    overflow: hidden;
    .top-box{
        border-top:	#59a1ff 1px solid;
        width: 1400px;
        height: 245px;
        margin:0 auto;
        position: relative;
        .left-box{
            position: absolute;
            left: 0;
            top:0;
            width:300px;
            height: 200px;
            .tel-box{
                padding-top:98px;
                text-align: left;
                h4{
                    margin-bottom: 15px;
                    font-size: 20px;
                    text-indent: 28px;
                }
                .one{
                  color: #fff;
                  font-size: 20px;
                   width: 204px;
                   height: 38px;
                   line-height: 38px;
                   background-color: #024fd0;
                   border-radius: 19px;
                   text-align: center;
                }
            }
        }
        .center-box{
             color: #fff;
             font-size: 14px;
             height: 14px;
             padding-top:93px;
             text-align: center;
             p{
               margin-bottom: 10px;
             }
            span{
              color:#fff;
            }
        }
        .right-box{
            position: absolute;
            top:0;
            right: 0;
            width: 134px;
            margin-top:74px;
            .img-txt{
                display: inline-block;
                font-size: 12px;
                color: #fff;
                margin-bottom: 12px;
            }
            .code-img{
                width:96px;
                height: 96px;
                overflow: hidden;
                padding:6px;
                border: solid 1px #00d4b9;
                margin-bottom: 6px;
            }
        }
    }
    
}
.right-fix-box{
    position: fixed;
    position: fixed;
    height: 240px;
    top: 30%;
    right: 0;
    display: none;
    z-index:99999;
    .fixed {
        .fixed-box {
            position: relative;
            cursor: pointer;
            width: 64px;
            height: 64px;
            margin-bottom: 10px;
            background-color: rgba(17, 101, 225,.8);
            color:#fff;
           .words {
                line-height: 70px;
                .next-icon {
                    display: inline-block;
                    font-style: normal;
                    text-transform: none;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    line-height: 55px;
                    height: 30px;
                }
            }
            
           .fixedDisplay {
                position: absolute;
                top: 0;
                right: 64px;
                font-size: 14px;
                display: none;
                padding-right: 20px;
                .box{
                    min-width: 150px;
                    min-height: 64px;
                    padding: 10px;
                    box-sizing: border-box;
                    background-color: rgba(17, 101, 225,.8);
                    position: relative;
                    &::after{
                        width:0;
                        height:0;
                        border: 10px solid transparent;
                        border-left-color:rgba(17, 101, 225,.8);;
                        position:absolute;
                        content:'';
                        position: absolute;
                        right: -20px;
                        top: 20px;
                    }
                }
            }
        .ant-back-top{
            position: absolute;
            top:0px;
            right: 12px;
            color:#fff;
        }
        &:hover{
            .fixedDisplay{
                display:block
            }
        } 
       
       }
    }
    &.show{
        display: block;
    }
}
a:visited {
    text-decoration: none;
}
@media only screen  and (max-width: 1600px) {
    .footer-box{
        .top-box{
            width: 1200px;
            .left-box{
                 width:300px;
            }
        }
    }
}
.fontColor{
   color: #fff;
}
.linehight{
  line-height:44px;
}
</style>

